<template>
  <v-container fluid>
    <game-stepper
      :currentSiteId="siteId"
      :currentSiteUuid="currentSiteUuid"
      :currentSiteLangs="availableLangs" />
  </v-container>
</template>

<script>
import GameStepper from '../components/game/GameStepper.vue';

export default {
  components: {
    GameStepper,
  },
  computed: {
    siteId() {
      return `${this.$route.params.siteId}`;
    },
    currentSiteUuid() {
      return this.$store.getters['site/allSites'][this.siteId].uuid;
    },
    availableLangs() {
      const localeIDs = this.$store.getters['site/allSites'][this.siteId].locales;
      const locsForSite = [];
      localeIDs.forEach((l) => {
        locsForSite.push(this.$store.state.site.locales[l].locale);
      });
      return locsForSite;
    },
  },
};
</script>
