<template>
  <v-card v-if="destination">
    <v-card-title>{{ $t("pageTitle") }}</v-card-title>
    <v-card-subtitle>
      {{ $t("pageSubtitle") }}
    </v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col>
            <v-divider />
            <h6>{{ $t("locationTitle") }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableGeolocation"
              :label="$t('labelSwitchEnableLocation')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="destination.locationUpdateInterval"
              :label="$t('labelLocationUpdateInterval')"
              type="number"
              :disabled="destination.enableGeolocation === false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableGeolocationRouting"
              :label="$t('labelSwitchEnableLocationRouting')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
            <h6>{{ $t("titleAccountManagement") }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableLogin"
              :label="$t('labelSwitchEnableLogin')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableRegistration"
              :label="$t('labelSwitchEnableRegistration')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableCheckin"
              :disabled="enableCheckinIsDisable"
              :label="$t('labelSwitchEnableCheckin')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="destination.baseCheckinDistance"
              :label="$t('labelFieldCheckinDistance')"
              type="number"
              :disabled="destination.enableCheckin === false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enablePolls"
              :label="$t('labelSwitchEnableLegacyPolls')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="destination.pollsScore"
              :label="$t('labelFieldPollsScore')"
              type="number"
              :disabled="destination.enablePolls === false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableRewards"
              :label="$t('labelSwitchEnableRewards')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableBookmarks"
              :label="$t('labelSwitchEnableBookmarks')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
            <h6>{{ $t("titleMapbox") }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="destination.mapboxToken"
              required
              :label="$t('labelFieldMapboxToken')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="destination.mapboxTheme"
              required
              :label="$t('labelFieldMapboxTheme')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
            <h6>{{ $t("titleTechParams") }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :rules="[validateStdText]"
              v-model="destination.cssTheme"
              :label="$t('labelFieldCSSTheme')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              required
              v-model.number="destination.matomoSiteId"
              :label="$t('labelFieldMatomoSiteID')"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableOpenAgenda"
              :label="$t('labelSwitchEnableOpenAgenda')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              required
              v-model="destination.openAgendaUrl"
              :label="$t('labelFieldAgendaURL')"
              :rules="[validateStdText]"
              :disabled="destination.enableOpenAgenda === false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model.number="destination.dataUpdateInterval"
              required
              :label="$t('labelFieldDataUpdateInterval')"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.useGameApi"
              :label="$t('labelSwitchUseGameAPI')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="destination.restrictContentToPlayers"
              :label="$t('labelSwitchRestrictContentToPlayers')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableMainPoiList"
              :label="$t('labelSwitchEnableMainPoiList')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="destination.allowPoiSorting"
              :label="$t('labelSwitchAllowPoiSoring')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableCategoriesList"
              :label="$t('labelSwitchEnableCategoriesList')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="destination.enableHelpButton"
              :label="$t('labelSwitchEnableHelpButton')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
            <h6>{{ $t("titleGeneralParams") }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="destination.platformType"
              :label="$t('labelSelectPlatformType')"
              :items="platformTypes"
            />
          </v-col>
          <v-col cols="6">
            <!-- customer type -->
            <v-select
              v-model="destination.customerType"
              item-text="text"
              item-value="value"
              :label="$t('labelSelectCustomerType')"
              :items="customerTypes"
            />
          </v-col>
        </v-row>
        <v-row v-if="destination.platformType === 'indoor'">
          <v-col cols="6">
            <v-switch
              v-model="destination.indoorExpandPOIDescription"
              :label="$t('labelSwitchExpandPoiDescription')"
              color="accent"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <!-- customer type -->
            <v-switch
              v-model="destination.indoorUseQRCodeScanner"
              :label="$t('labelSwitchQrCodeScanner')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-else-if="destination.platformType === 'outdoor' || destination.platformType === 'urbanrace' ">
          <v-col cols="6">
            <v-switch
              v-model="destination.outdoorHideCheckinPopup"
              :label="$t('labelSwitchOutdoorHideCheckinPopup')"
              color="accent"
              hide-details
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable max-len */
import rules from '../../services/validation';

const kPlatformTypes = Object.freeze({
  OUTDOOR: 'outdoor',
  INDOOR: 'indoor',
  ICEBREAKER: 'icebreaker',
  URBANRACE: 'urbanrace',
  NIGHTQUIZ: 'night quiz',
});
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      destination: null,
      cssTheme: null,
      enableCheckinIsDisable: false,
    };
  },
  computed: {
    customerTypes() {
      return [
        { text: this.$t('customerTypeUnknwown'), value: 'unknown' },
        { text: this.$t('customerTypeDotmap'), value: 'dotmap' },
        { text: this.$t('customerTypeEvent'), value: 'final_event' },
        { text: this.$t('customerTypeAgency'), value: 'agency' },
        { text: this.$t('customerTypeSchool'), value: 'school' },
        { text: this.$t('customerTypeTourism'), value: 'final_tourism' },
        { text: this.$t('customerTypeDemo'), value: 'demo' },
      ];
    },
    platformTypes() {
      return Object.values(kPlatformTypes);
    },
  },
  watch: {
    value() {
      this.destination = this.value;
    },
    destination: {
      handler(newDestination) {
        if (newDestination.platformType === kPlatformTypes.OUTDOOR) {
          this.destination.enableCheckin = true;
          this.enableCheckinIsDisable = true;
        } else {
          this.enableCheckinIsDisable = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.$emit('input', this.destination);
    },
    validateStdText(str) {
      return (
        rules.ValidateMinLength(str, 1) && rules.ValidateMaxLength(str, 255)
      );
    },
  },
  mounted() {
    this.destination = this.value;
  },
};
</script>

<i18n>
{
  "fr": {
    "pageTitle": "Paramètres de la destination",
    "pageSubtitle": "Gérez les réglages du comportement de l'application pour votre application",
    "locationTitle": "Paramètres de géolocalisation",
    "labelSwitchEnableLocation": "Activer la géolocalisation",
    "labelLocationUpdateInterval": "Délai de mise à jour de la position GPS (en secondes)",
    "labelSwitchEnableLocationRouting": "Activer le guidage GPS",
    "titleAccountManagement": "Gestion des Comptes et récompenses",
    "labelSwitchEnableLogin": "Activer la connexion",
    "labelSwitchEnableRegistration": "Activer la création de compte",
    "labelSwitchEnableCheckin": "Activer les checkins",
    "labelFieldCheckinDistance": "Distance de checkin (en mètres)",
    "labelSwitchEnableLegacyPolls": "Activer les Quizz (hors mode évènement)",
    "labelFieldPollsScore": "Score d'un quiz",
    "labelSwitchEnableRewards": "Activer les récompenses",
    "labelSwitchEnableBookmarks": "Activer les favoris",
    "titleMapbox": "Paramètres de la carte Mapbox",
    "labelFieldMapboxToken": "Token mapbox",
    "labelFieldMapboxTheme": "Thème mapbox",
    "titleTechParams": "Paramètres techniques",
    "labelFieldCSSTheme": "Thème CSS",
    "labelFieldMatomoSiteID": "ID Site Matomo",
    "labelSwitchEnableOpenAgenda": "Activer Open Agenda",
    "labelFieldAgendaURL": "URL de l'agenda",
    "labelFieldDataUpdateInterval": "Fréquence de mise à jour des données (en secondes)",
    "labelSwitchUseGameAPI": "Activer le mode évènement (jeu en équipe)",
    "labelSwitchRestrictContentToPlayers": "Restreindre le contenu uniquement aux équipes connectées",
    "labelSwitchEnableMainPoiList": "Autoriser l'affichage de la liste de POIs",
    "labelSelectPlatformType": "Type de plateforme",
    "titleGeneralParams": "Paramètres généraux",
    "labelSelectCustomerType": "Type de client",
    "customerTypeUnknwown": "non défini",
    "customerTypeTourism": "Client final tourisme",
    "customerTypeEvent": "Client final évènementiel",
    "customerTypeAgency": "Agence d'évènementiel",
    "customerTypeSchool": "Ecole",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Démo",
    "labelSwitchAllowPoiSoring": "Autoriser le tri manuel des lieux (indoor uniquement)",
    "labelSwitchEnableCategoriesList": "Afficher la liste des catégories (filtres)",
    "labelSwitchEnableHelpButton": "Afficher le bouton d'aide",
    "labelSwitchQrCodeScanner": "Activer le scanner de QR Code (indoor uniquement)",
    "labelSwitchExpandPoiDescription": "Afficher la description des lieux par défaut (indoor uniquement)",
    "labelSwitchOutdoorHideCheckinPopup": "Masquer la popup de checkin"
  },
  "en": {
    "pageTitle": "Destination parameters",
    "pageSubtitle": "Manage application general behavior",
    "locationTitle": "Location parameters",
    "labelSwitchEnableLocation": "Enable location tracking",
    "labelLocationUpdateInterval": "Location update interval (in seconds)",
    "labelSwitchEnableLocationRouting": "Enable GPS guiding",
    "titleAccountManagement": "Accounts and rewards management",
    "labelSwitchEnableLogin": "Enable login",
    "labelSwitchEnableRegistration": "Enable regsitration",
    "labelSwitchEnableCheckin": "Enable checkin",
    "labelFieldCheckinDistance": "Checkin distance (in meters)",
    "labelSwitchEnableLegacyPolls": "Activate legacy quizz(without event mode)",
    "labelFieldPollsScore": "Quiz points",
    "labelSwitchEnableRewards": "Enable rewards",
    "labelSwitchEnableBookmarks": "Enable bookmarks",
    "titleMapbox": "Mapbox parameters",
    "labelFieldMapboxToken": "Mapbox token",
    "labelFieldMapboxTheme": "Mapbox theme",
    "titleTechParams": "Technical parameters",
    "labelFieldCSSTheme": "CSS theme",
    "labelFieldMatomoSiteID": "Matomo site ID",
    "labelSwitchEnableOpenAgenda": "Enable Open Agenda",
    "labelFieldAgendaURL": "Agenda URL",
    "labelFieldDataUpdateInterval": "Data update frequency (in seconds)",
    "labelSwitchUseGameAPI": "Enable event mode (Team play)",
    "labelSwitchRestrictContentToPlayers": "Restrict content display to connected teams",
    "labelSwitchEnableMainPoiList": "Enable the main POIs list",
    "labelSelectPlatformType": "Platform type",
    "titleGeneralParams": "General parameters",
    "labelSelectCustomerType": "Customer type",
      "customerTypeUnknwown": "Undefined",
    "customerTypeTourism": "Tourism",
    "customerTypeEvent": "Events (direct customer)",
    "customerTypeAgency": "Event agency",
    "customerTypeSchool": "School",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Demo",
    "labelSwitchAllowPoiSoring": "Allow POIs sorting(indoor only)",
    "labelSwitchEnableCategoriesList": "Display the categories list",
    "labelSwitchEnableHelpButton": "Display the help button",
    "labelSwitchQrCodeScanner": "Enable QR Code scanner (indoor only)",
    "labelSwitchExpandPoiDescription": "Expand POI description by default (indoor only)",
    "labelSwitchOutdoorHideCheckinPopup": "Hide checkin popup"

  }
}
</i18n>
