<template>
  <v-container fluid v-if="game && currentDestination">
    <v-dialog v-model="displayEndConfirmDialog">
      <confirmation-dialog
        :title="$t('confirmeEndGameTitle')"
        :subtitle="$t('confirmEndGameSubtitle')"
        :cancelButtonTitle="$t('confirmEndGameCancelBtn')"
        :confirmButtonTitle="$t('confirmEndGameConfirmBtn')"
        @cancel="displayEndConfirmDialog = false"
        @confirm="confirmEndGame"
        v-show="displayEndConfirmDialog === true" />
    </v-dialog>
    <v-card>
      <!-- mobile toolbar -->
      <v-toolbar dense class="d-block d-sm-none" color="white" flat>
        <v-toolbar-title>{{ $t('gameText') }} <span v-if="game">{{ game.name }}</span> </v-toolbar-title>
        <v-spacer />
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list subheader>
            <v-subheader>Statut</v-subheader>
            <v-list-item>
              <v-list-item-title class="text-center">
                <game-status v-if="game" :status="game.status" />
              </v-list-item-title>
            </v-list-item>
            <v-list- v-if="showActionButton" @click="updateStatus()">
              <v-list-item-title>
                {{ actionButton }}
              </v-list-item-title>
            </v-list->
            <v-divider />
            <v-subheader>Actions</v-subheader>
            <menu-action-export-pictures
              :party-status="game.status"
              :archive-status="game.pictureArchiveStatus"
            />
            <menu-action-download-pictures-export
              v-if="game.pictureArchivePath && game.pictureArchivePath.length > 0"
              :link="game.pictureArchivePath" />
            <menu-action-add-team
              v-if="game"
              :should-display-menu="game && game.isTest === false"
              :organization="organization"
              :game="game"
              :teams-length="teams.length"
              @close="refreshScoreboard"
              @teamAdded="consumeCredit"
            />
            <menu-action-generate-p-d-f
              v-if="currentDestination.domains
                && currentDestination.domains.length > 0 && game"
              :game="game"
              :teams="teams"
              :domain="currentDestination.domains[0]"
              :platform-type="currentDestination.platformType"
            />
            <menu-action-export-scoreboard
              :scoreboard="scoreboard"
            />
            <v-list-item @click="openGalleryUrl">
              <v-list-item-title>
                Galerie
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar>
      <!-- desktop toolbar -->
      <v-toolbar class="d-none d-sm-block" color="white" flat>
        <v-toolbar-title>
          {{ $t('gameText') }} <span v-if="game">{{ game.name }}</span>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical />
        <v-toolbar-title>
          {{ $t('stateText') }} <game-status v-if="game" :status="game.status" />
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical />
        <v-btn small class="ml-2" v-if="showActionButton" :loading="isUpdating" color="primary" @click="updateStatus()">
          {{ actionButton }}
        </v-btn>
        <v-spacer />
        <v-menu bottom left>

          <template v-slot:activator="{ on }">

            <v-btn small :loading="isUpdating" color="primary" v-on="on">
              Actions
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <menu-action-add-team
              v-if="game"
              :should-display-menu="game && game.isTest === false"
              :organization="organization"
              :game="game"
              :teams-length="teams.length"
              @close="refreshScoreboard"
              @teamAdded="consumeCredit"
            />
            <menu-action-generate-p-d-f
              v-if="currentDestination.domains && currentDestination.domains.length > 0 && game"
              :game="game"
              :teams="teams"
              :domain="currentDestination.domains[0]"
              :platform-type="currentDestination.platformType"
            />
            <menu-action-export-scoreboard
              :scoreboard="scoreboard"
            />
            <menu-action-export-pictures
              :party-status="game.status"
              :archive-status="game.pictureArchiveStatus"
              @generate="startPhotoExport"
            />
            <menu-action-download-pictures-export
              v-if="game.pictureArchivePath && game.pictureArchivePath.length > 0"
              :link="game.pictureArchivePath" />
            <v-list-item @click="openGalleryUrl(galleryUrl)">
              <v-list-item-title>
                Galerie
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openGalleryUrl(galleryV2Url)">
              <v-list-item-title>
                Galerie V2
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar>
      <v-tabs color="accent" v-model="tabs" grow>
        <v-tab key="scoreboard">Scoreboard</v-tab>
        <v-tab v-if="shouldDisplayQuestionsTab === true" key="questions">Questions</v-tab>
        <v-tab v-if="shouldDisplayProgressTab === true" key="progress">Progression</v-tab>
        <v-tab key="reviews">
          <v-badge
            v-if="pendingReviews.length > 0"
            :content="pendingReviews.length">Corrections
          </v-badge>
          <span v-else>Corrections</span>
        </v-tab>
        <v-tab key="params">Paramètres de la partie</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item key="scoreboard">
          <v-container fluid>
            <h2 class="text-h4">{{ $t('tabsScore') }}</h2>
            <game-scoreboard
              v-if="game"
              :destination="currentDestination"
              :game="game"
              :site-id="siteId"
              :scoreboard="scoreboard"
              :game-id="gameId"
              @needs-refresh="refreshScoreboard" />
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="shouldDisplayQuestionsTab === true" key="questions">
          <v-container fluid>
            <questions-editor
              :destination-id="currentDestination.uuid"
              :token="token" />
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="shouldDisplayProgressTab === true" key="progress">
          <v-container fluid>
            <h2 class="text-h4">{{ $t('tabsProgress') }}</h2>
            <game-poi-checkins-per-team :site-id="siteId" :game-id="gameId" />
          </v-container>
        </v-tab-item>
        <v-tab-item key="reviews">
          <v-container fluid>
            <h2 class="text-h4">{{ $t('tabsPendingReviews') }} </h2>
            <game-reviews-list
              :party-id="game.id"
              :reviews="pendingReviews"
              @needs-refresh="refreshScoreboard"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item key="params">
          <v-container fluid>
            <h2 class="text-h4">{{ $t('tabsPartySettings')}}</h2>
            <game-settings
              :destination-id="siteId"
              :party-id="game.id"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { parseISO } from 'date-fns';

import {
  GET_PARTY, GET_SCOREBOARD, GET_PENDING_REVIEWS, UPDATE_PARTY,
} from '../store/actionTypes';

import ConfirmationDialog from '../components/base/ConfirmationDialog.vue';
import GameScoreboard from '../components/game/GameScoreboard.vue';
import QuestionsEditor from '../components/game/simpleQuestion/GameQuestionsEditror.vue';
import GamePoiCheckinsPerTeam from '../components/game/GamePoiCheckinsPerTeam.vue';
import GameStatus from '../components/game/GameStatus.vue';
import GameReviewsList from '../components/game/GameReviewsList.vue';
import GameSettings from '../components/game/GameSettings.vue';
import MenuActionAddTeam from '../components/game/actionsmenu/AddTeamAction.vue';
import MenuActionGeneratePDF from '../components/game/actionsmenu/GetTeamsQRCodePDF.vue';
import MenuActionExportScoreboard from '../components/game/actionsmenu/ExportScoreboard.vue';
import MenuActionExportPictures from '../components/game/actionsmenu/GeneratePicturesExport.vue';
import MenuActionDownloadPicturesExport from '../components/game/actionsmenu/DownloadPicturesExport.vue';
import UserNotifications from '../mixins/UserNotifications.vue';
import http from '../services/axios';
import fetchApi from '../services/fetch';
import creditCrud from '../services/credits';
import { DESTINATION_TYPE } from '../const';
import cfg from '../config';
import { PARTY_STATUS } from '../components/game';

function sortTeam(teamA, teamB) {
  if (teamA.score < teamB.score) {
    return 1;
  }
  if (teamA.score > teamB.score) {
    return -1;
  }
  return 0;
}
export default {
  components: {
    GameScoreboard,
    GamePoiCheckinsPerTeam,
    GameStatus,
    ConfirmationDialog,
    MenuActionAddTeam,
    MenuActionGeneratePDF,
    MenuActionExportScoreboard,
    GameReviewsList,
    GameSettings,
    QuestionsEditor,
    MenuActionExportPictures,
    MenuActionDownloadPicturesExport,
  },
  mixins: [UserNotifications],
  data() {
    return {
      tabs: null,
      game: null,
      scoreboard: [],
      teams: [],
      isUpdating: false,
      organization: null,
      polling: null,
      displayEndConfirmDialog: false,
      pendingReviews: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['token', 'user']),
    ...mapGetters('poi', [
      'byUuid',
    ]),
    ...mapState('site', ['sites']),

    siteId() {
      return `${this.$route.params.siteId}`;
    },
    gameId() {
      return this.$route.params.gameId;
    },
    currentDestination() {
      return this.sites[this.siteId];
    },
    galleryUrl() {
      return `https://photos.dotmap.fr/gallery/${this.game.id}`;
    },
    galleryV2Url() {
      return `https://my.dotmap.fr/party/${this.game.id}`;
    },
    shouldDisplayQuestionsTab() {
      return this.currentDestination.platformType === DESTINATION_TYPE.NIGHTQUIZ;
    },
    shouldDisplayProgressTab() {
      switch (this.currentDestination.platformType) {
        case DESTINATION_TYPE.NIGHTQUIZ:
          return false;

        case DESTINATION_TYPE.ICEBREAKER:
          return false;

        default:
          return true;
      }
    },
    actionButton() {
      switch (this.scoreboard.partyStatus) {
        case 'pending':
          return this.$t('statusActionStart');
        case 'started':
          return this.$t('statusActionEnd');
        case 'ended':
          return this.$t('statusActionRestart');
        default:
          return this.scoreboard.partyStatus;
      }
    },
    showActionButton() {
      return this.scoreboard.partyStatus === PARTY_STATUS.PENDING
        || this.scoreboard.partyStatus === PARTY_STATUS.STARTED
        || this.user.organizationId === 4;
    },
  },
  async mounted() {
    await this.fetchPOIs({ siteId: this.siteId, token: this.token, status: 'all' });
    await this.getOrganizationById(this.user.organizationId, this.token);
    await this.refreshScoreboard();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions({
      getGame: GET_PARTY,
      getScoreboard: GET_SCOREBOARD,
      getReviews: GET_PENDING_REVIEWS,
      updateGame: UPDATE_PARTY,
      fetchPOIs: 'poi/all',
    }),
    pollData() {
      if (this.polling === null) {
        this.polling = setInterval(() => {
          this.refreshScoreboard();
        }, 10000);
      }
    },
    formatDate(rfcDate) {
      const rawDate = parseISO(rfcDate);
      return this.$d(rawDate, 'short', this.$i18n.locale);
    },

    setScoreboard(scoreboard) {
      this.scoreboard = scoreboard;
      const { teams } = scoreboard;

      teams.sort(sortTeam);
      for (let i = 0; i < teams.length; i += 1) {
        teams[i].position = i + 1;

        if (teams[i].events && teams[i].events.length > 0) {
          teams[i].lastEvent = this.buildLastEvent(teams[i].events[0]);
          teams[i].lastEventDate = this.formatDate(teams[i].events[0].createdAt);
        } else {
          teams[i].lastEvent = this.$t('textNoActivity');
        }
      }
      this.teams = teams;
    },

    /*
     * Builds a string to present the last event Cell of the scoreboard based on the event type
     */
    buildLastEvent(event) {
      let eventTitle = '';
      const eventType = event.type;
      let poiName = 'Aucun';
      const poi = this.byUuid[event.poiId];
      if (poi) {
        poiName = poi.name;
      }
      switch (eventType) {
        case 'checkin':
        case 'qrcode':
          eventTitle = poiName;
          break;
        case 'question':
          eventTitle = event.content.questionTitle;
          break;
        case 'bonus':
          eventTitle = event.content.reason;
          break;
        case 'clue':
          eventTitle = `${event.content.questionName} | ${event.content.clueContent}`;
          break;
        default:
          eventTitle = poiName;
          break;
      }
      return ` ${eventType} | ${eventTitle}`;
    },
    confirmEndGame() {
      this.isUpdating = true;
      this.game.status = PARTY_STATUS.ENDED;
      this.updateGame({
        game: this.game,
        token: this.token,
      })
        .then(() => {
          this.displayEndConfirmDialog = false;
          this.isUpdating = false;
          this.refreshScoreboard();
        })
        .catch((error) => {
          this.displayEndConfirmDialog = false;
          this.isUpdating = false;
          this.errorNotification(this.$t('notificationCantEndParty'), error);
        });
    },
    goTo(path) {
      this.$router.push({
        name: path,
        params: {
          siteId: this.siteId,
          partyId: this.gameId,
        },
      });
    },
    updateStatus() {
      if (this.game.status === PARTY_STATUS.PENDING) {
        this.isUpdating = true;
        this.game.status = PARTY_STATUS.STARTED;
        this.updateGame({
          game: this.game,
          token: this.token,
        })
          .then(() => {
            this.isUpdating = false;
            this.refreshScoreboard();
          })
          .catch((error) => {
            this.isUpdating = false;
            this.errorNotification(this.$t('notificationCantStartParty'), error);
          });
      } else if (this.game.status === PARTY_STATUS.STARTED) {
        this.displayEndConfirmDialog = true;
      } else if (this.game.status === PARTY_STATUS.ENDED) {
        this.isUpdating = true;
        this.game.status = PARTY_STATUS.PENDING;
        this.updateGame({
          game: this.game,
          token: this.token,
        })
          .then(() => {
            this.isUpdating = false;
            this.refreshScoreboard();
          })
          .catch((error) => {
            this.isUpdating = false;
            this.errorNotification(this.$t('notificationCantStartParty'), error);
          });
      }
    },
    async getOrganizationById(id, token) {
      const response = await http.get(`/api/v3/organizations/${id}`, { headers: { Authorization: `BEARER ${token}` } });
      const organization = response.data;
      this.organization = organization;
    },
    refreshScoreboard() {
      Promise.all([
        this.getScoreboard({ gameId: this.gameId, token: this.token }),
        this.getGame({ gameId: this.gameId, token: this.token }),
      ])
        .then((values) => {
          const [scoreboard, game] = values;
          this.game = game;
          this.setScoreboard(scoreboard);
          this.pollData();
          this.getReviews({ partyId: this.gameId, token: this.token })
            .then((reviews) => {
              if (reviews != null && reviews.length > 0) {
                this.pendingReviews = reviews;
              } else {
                this.pendingReviews = [];
              }
            })
            .catch((error) => {
              this.errorNotification(this.$t('notificationErrorFetchingReviews'), error);
            });
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationErrorFetchingParties'), error);
        });
    },
    async consumeCredit() {
      try {
        const req = [];
        req.push({
          organizationId: this.organization.id,
          partyId: this.game.id,
          partyName: this.game.name,
          editorEmail: this.user.email,
          amount: 1,
        });
        this.isUpdating = true;
        await creditCrud.consumeCredits(req[0], this.token);
        await this.getOrganizationById(this.user.organizationId, this.token);
        this.isUpdating = false;
      } catch (error) {
        console.log(error);
        this.isUpdating = false;
      }
    },
    async exportScoreboard() {
      const scoreboardToExport = await this.getScoreboard(
        { gameId: this.gameId, token: this.token },
      );
      const csvScore = Object.values(scoreboardToExport.teams);
      const csvData = [];
      const trimmedCsv = csvScore.map((element) => ({
        name: element.name,
        score: element.score,
        activity: element.updatedAt,
      }));
      csvData.push(trimmedCsv);
      let csv = `${this.$t('scoreboardCSVHeader')}\n`;
      csvData[0].forEach((row) => {
        csv += `${row.activity}, ${row.name}, ${row.score} \n`;
      });
      const csvHref = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      const link = document.createElement('a');
      link.setAttribute('href', csvHref);
      link.setAttribute('download', 'scoreboard.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openGalleryUrl(url) {
      window.open(url);
    },
    async startPhotoExport() {
      try {
        await fetchApi.postJSON(`${cfg.gameApiUrl}/gameapi/v1/parties/${this.gameId}/export-pictures`, {}, this.token);
        this.refreshScoreboard();
        this.successNotification(this.$t('notificationExportStarted'));
      } catch (error) {
        this.errorNotification(this.$t('notificationExportError'), error);
      }
    },
  },
};
</script>
<i18n>
  {
  "fr": {
    "gameText": "Partie :",
    "stateText": "Etat : ",
    "loadingText": "Chargement en cours",
    "pendingCorrections": "Pas de correction en attente | Une correction en attente | {count} corrections en attente",
    "deleteTeamTitle": "Supprimer l'équipe",
    "deleteTeamText": "Voulez-vous supprimer cette équipe ?",
    "deleteTeamBtnNo": "Non",
    "deleteTeamBtnYes": "Oui",
    "editTeamBtn": "Modifier",
    "deleteBtnTitle": "Supprimer",
    "qrCodeBtn": "Voir le QR Code",
    "tableHeaderTeamName": "Nom d'équipe",
    "tableHeaderTeamEmail": "Email d'équipe",
    "tableaHeaderLastActivity": "Dernière activité",
    "tableHeaderLastActivityTime": "Heure de dernière activité",
    "tableHeaderScore": "Points",
    "tableHeaderActions": "Actions",
    "statusActionStart": "Démarrer",
    "statusActionEnd": "Terminer",
    "statusActionRestart": "Redémarrer",
    "notificationCantStartParty": "Impossible de commencer la partie",
    "notificationCantEndParty": "Impossible de terminer la partie",
    "notificationCantDeleteTeam": "Impossible de supprimer l'équipe",
    "notificationCantUpdateTeam": "Impossible de mettre à jour l'équipe",
    "notificationBonusAdded": "Bonus ajouté",
    "notificationCantAddBonus": "Impossible d'ajouter les points bonus",
    "textNoActivity": "Pas encore d'activité",
    "notificationErrorFetchingReviews": "Impossible de récupérer la liste des revues pour cette partie",
    "notificationErrorFetchingParties": "Impossible de récupérer la liste des parties",
    "notificationErrorNoHistory": "Pas encore d'historique pour cette équipe",
    "confirmeEndGameTitle": "Terminer la partie ?",
    "confirmEndGameSubtitle": "Les équipes ne pourront plus jouer.",
    "confirmEndGameCancelBtn": "Annuler",
    "confirmEndGameConfirmBtn": "Terminer la partie",
    "addTeamDialogBtn": "Ajouter une équipe",
    "exportScoreboard": "Obtenir les scores",
    "scoreboardCSVHeader": "Derniere activité, Nom de l'équipe, Score",
    "insufficientCredits": "Nombre de crédits équipe insuffisant",
    "qrHelper": "Assistance",
    "qrIssue": "En cas de problème avec votre téléphone, scannez ce QR code pour reprendre la partie sur un nouveau téléphone",
    "poiCheckinsPerTeam": "Suivre les checkins des POIs",
    "tabsPartySettings": "Paramètres de la partie",
    "tabsPendingReviews": "Corrections en attente",
    "tabsProgress": "Progression de la partie",
    "tabsScore": "Scores",
    "notificationExportStarted": "Export démarré, il sera disponible dans quelques minutes",
    "notificationExportError": "Erreur lors du démarrage de l'export"
  },
  "en": {
    "gameText": "Game:",
    "stateText": "State: ",
    "loadingText": "Loading...",
    "pendingCorrections": "No pending review | One pending review | {count} pending reviews",
    "deleteTeamTitle": "Delete team",
    "deleteTeamText": "Do you want to delete this team?",
    "deleteTeamBtnNo": "No",
    "deleteTeamBtnYes": "Yes",
    "editTeamBtn": "Edit",
    "deleteBtnTitle": "Delete",
    "qrCodeBtn": "Show the QR Code",
    "tableHeaderTeamName": "Team name",
    "tableHeaderTeamEmail": "Team email",
    "tableaHeaderLastActivity": "Last activity name",
    "tableHeaderLastActivityTime": "Last activity time",
    "tableHeaderScore": "Points",
    "tableHeaderActions": "Actions",
    "statusActionStart": "Start",
    "statusActionEnd": "End",
    "statusActionRestart": "Restart",
    "notificationCantStartParty": "Unable to start the game",
    "notificationCantEndParty": "Unable to end the game",
    "notificationCantDeleteTeam": "Unable to delete the team",
    "notificationCantUpdateTeam": "Unable to update the team",
    "notificationBonusAdded": "Bonus added",
    "notificationCantAddBonus": "Unable to add the bonus",
    "textNoActivity": "No activity yet",
    "notificationErrorFetchingReviews": "Unable to retrieve reviews for this game",
    "notificationErrorFetchingParties": "Unable to retrieve games",
    "notificationErrorNoHistory": "No history for this team yet",
    "confirmeEndGameTitle": "End the game ?",
    "confirmEndGameSubtitle": "The teams will not be able to play anymore.",
    "confirmEndGameCancelBtn": "Cancel",
    "confirmEndGameConfirmBtn": "End game",
    "addTeamDialogBtn": "Add a team",
    "exportScoreboard": "Get scoreboard",
    "scoreboardCSVHeader": "Last activity, Team name, Score",
    "insufficientCredits": "Number of team credits insufficient",
    "qrHelper": "Support",
    "qrIssue": "In case of phone issue, please scan this QR Code to continue playing on another phone",
    "poiCheckinsPerTeam": "Track the POI checkins",
    "tabsPartySettings": "Game settings",
    "tabsPendingReviews": "Pending reviews",
    "tabsProgress": "Game progress",
    "tabsScore": "Scores",
    "notificationExportStarted": "Export started, it will be available in a few minutes",
    "notificationExportError": "Error while starting the export"
  }
}
</i18n>
