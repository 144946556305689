<template>
  <!-- eslint-disable -->
  <v-card class="mb-12" flat>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="game.name"
                :label="$t('labelFieldName')"
                counter="1024"
                required
                :rules="nameRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-if="sortedDestinations.length > 0"
                v-model="game.destinationId"
                :items="sortedDestinations"
                :rules="destinationRules"
                :label="$t('labelFieldDestination')"
                item-text="name"
                item-value="uuid"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select

                v-model="game.lang"
                :items="formatedLangs"
                required
                :rules="langRules"
                :label="$t('labelFieldLang')"

              ></v-select>
            </v-col>
          </v-row>
            <v-row>
            <v-col>
              <v-text-field
                v-model="game.phoneNumber"
                :label="$t('labelFieldPhoneNumber')"
                counter="20"
                required
                :rules="phoneRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-datetime-picker
                dateFormat="dd/MM/yyyy"
                :text-field-props="startDateProps"
                :label="$t('labelFieldStartDate')"
                v-model="game.startDate"
                :date-picker-props="startDatePickerProps"
                :time-picker-props="timeProps"
              >
                <template v-slot:actions="props">
                  <v-btn color="primary" @click.native="props.parent.clearHandler">
                    {{ $t('btnClose') }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="props.parent.okHandler">
                    {{ $t('btnValidate') }}
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-datetime-picker
                :date-picker-props="endDatePickerProps"
                :time-picker-props="timeProps"
                dateFormat="dd/MM/yyyy"
                :label="$t('labelFieldEndDate')"
                v-model="game.endDate"
                :text-field-props="endDateProps"
              >
                <template v-slot:actions="props">
                  <v-btn color="primary" @click.native="props.parent.clearHandler">
                    {{ $t('btnClose') }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="props.parent.okHandler">
                    {{ $t('btnValidate') }}
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
              v-model="game.shouldSendWelcomeEmail"
              :label="$t('labelCheckboxEmail')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-n8">
              <v-checkbox
              v-model="game.isTest"
              :label="$t('labelTestGame')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-n8">
              <v-checkbox
              v-model="game.autoStart"
              :label="$t('labelAutoStart')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-n8">
              <v-checkbox
              v-model="game.autoClose"
              :label="$t('labelAutoClose')" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';

const now = moment(new Date()).subtract(1, 'd');
export default {
  props: {
    game: {
      type: Object,
      required: true,
    },
    langs: {
      type: Array,
      required: true,
    },
  },
  watch: {
    valid(val) {
      this.$emit('valid', val);
    },
  },
  data() {
    return {
      timeProps: {
        format: '24hr',
      },
      startDatePickerProps: {
        locale: navigator.language || navigator.userLanguage,
        'allowed-dates': (val) => {
          const date = moment(val);
          return date.isSameOrAfter(now);
        },
      },
      endDatePickerProps: {
        locale: navigator.language || navigator.userLanguage,
        'allowed-dates': (val) => {
          const date = moment(val);
          return date.isSameOrAfter(now);
        },
      },
      startDateProps: {
        rules: [(v) => !!v || this.$t('ruleStartDate')],
      },
      endDateProps: {
        rules: [(v) => !!v || this.$t('ruleEndDate'), this.checkDate],
      },
      valid: false,
      nameRules: [
        (v) => !!v || this.$t('ruleNameEmpty'),
        (v) => (v && v.length <= 1024) || this.$t('ruleNameTooLong'),
        (v) => (v && v.length >= 2) || this.$t('ruleNameTooShort'),
      ],
      langRules: [
        (v) => !!v || this.$t('ruleLangEmpty'),
        (v) => (v && v.length <= 20) || this.$t('ruleLangTooLong'),
        (v) => (v && v.length >= 2) || this.$t('ruleLangTooShort'),
      ],
      phoneRules: [(v) => (!v || v.length <= 20) || this.$t('rulePhoneTooLong')],
      destinationRules: [(v) => !!v || this.$t('ruleDestination')],
    };
  },
  methods: {
    checkDate(v) {
      return (
        moment(this.game.startDate).isBefore(moment(v, 'DD/MM/YYYY HH:mm'))
        || this.$t('ruleEndDateAfterStartDate')
      );
    },
  },
  computed: {
    ...mapState('site', ['currentSiteId', 'sites', 'sitesLoadingSucceeded']),
    sortedDestinations() {
      const destValues = Object.values(this.sites);
      destValues.sort((a, b) => a.name - b.name);
      return destValues;
    },
    formatedLangs() {
      const res = [];
      this.langs.forEach((l) => {
        res.push({ text: this.$t(`labelLang_${l}`), value: l });
      });
      return res;
    },

  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Créer une partie",
    "labelFieldName": "Nom de la partie*",
    "labelFieldDestination": "Destination",
    "labelFieldStartDate": "Date de début",
    "labelFieldEndDate": "Date de fin",
    "labelFieldPhoneNumber": "Numéro de téléphone d'assistance",
    "btnClose": "Fermer",
    "btnValidate": "Valider",
    "labelCheckboxEmail": "Envoyer les informations de la partie par email aux équipes",
    "ruleStartDate": "Veuillez choisir une date de début",
    "ruleEndDate": "Veuillez choisir une date de fin",
    "ruleNameEmpty": "Veuillez saisir un nom",
    "ruleNameTooLong": "Le nom doit faire moins de 1024 caractères",
    "ruleNameTooShort": "Le nom doit faire au moins 2 caractères",
    "ruleLangEmpty": "Veuillez choisir une langue",
    "ruleLangTooLong": "La langue doit faire moins de 20 caractères",
    "ruleLangTooShort": "La langue doit faire au moins 2 caractères",
    "ruleDestination": "Veuillez choisir une destination",
    "ruleEndDateAfterStartDate": "Veuillez choisir une date de fin postérieure à la date de début",
    "rulePhoneTooLong": "le numéro de téléphone est trop long",
    "labelTestGame": "Ceci est une partie de test",
    "labelFieldLang": "Langue de la partie",
    "labelLang_fr_FR": "Français",
    "labelLang_en_GB":"Anglais" ,
    "labelLang_de_DE": "Allemand" ,
    "labelLang_it_IT": "Italien" ,
    "labelLang_nl_NL": "Néerlandais" ,
    "labelLang_es_ES": "Espagnol",
    "labelAutoClose": "Terminer automatiquement la partie à la date de fin (les joueurs ne pourront plus jouer).",
    "labelAutoStart": "Démarrer automatiquement la partie à la date de début (peut prendre 30 secondes)."
  },
  "en": {
    "title": "Create a game",
    "labelFieldName": "Name*",
    "labelFieldDestination": "Destination",
    "labelFieldStartDate": "Start date",
    "labelFieldEndDate": "End date",
    "labelFieldPhoneNumber": "Phone number for assistance",
    "btnClose": "Close",
    "btnValidate": "Save",
    "labelCheckboxEmail": "Send the game details by email to the teams",
    "ruleStartDate": "Please choose a start date",
    "ruleEndDate": "Please choose an end date",
    "ruleNameEmpty": "Please enter a name",
    "ruleNameTooLong": "Name must be less than 1024 chars long",
    "ruleNameTooShort": "Name must be at least 2 chars long",
    "ruleLangEmpty": "Please choose a language",
    "ruleLangTooLong": "Language must be less than 20 chars long",
    "ruleLangTooShort": "Language must be at least 2 chars long",
    "ruleDestination": "Please choose a destination",
    "ruleEndDateAfterStartDate": "Please choose an end date after the start date",
    "rulePhoneTooLong": "Phone number is too long",
    "labelTestGame": "This is a test game",
    "labelFieldLang": "Language of the game",
    "labelLang_fr_FR": "French",
    "labelLang_en_GB":"English" ,
    "labelLang_de_DE": "German" ,
    "labelLang_it_IT": "Italian" ,
    "labelLang_nl_NL": "Deutch" ,
    "labelLang_es_ES": "Spanish",
    "labelAutoClose": "Automatically end the game at its end date (players will no longer be able to play)",
    "labelAutoStart": "Automatically start the game at its start date (can take up to 30 seconds)."

  }
}
</i18n>
